const chevron = document.getElementById("sideNavIcon");
chevron.addEventListener("click", function () {
  this.classList.toggle("sideNav__chevron");
});

const listItems = document.querySelectorAll(".navList");
const navClick = document.getElementById("sideNavIcon");

navClick.addEventListener("click", function () {
  for (let item of listItems) item.classList.toggle("navList--collapsed");
});
